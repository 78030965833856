import ReactDOM from 'react-dom';

// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

// project imports
import * as serviceWorkerRegistration from 'serviceWorkerRegistration';
import App from 'App';
import { store } from 'store';

// style + assets
import 'assets/scss/style.scss';
import config from './config';

// ==============================|| REACT DOM RENDER  ||============================== //

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter basename={config.basename}>
            <App />
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

// const configuration = {
//     onUpdate: (registration) => {
//         if (registration && registration.waiting) {
//             if (window.confirm('Có phiên bản cập nhật mới! tải lại trang web để cập nhật!')) {
//                 registration.waiting.postMessage({ type: 'SKIP_WAITING' });
//                 window.location.reload();
//             }
//         }
//     }
// };

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorkerRegistration.register(configuration);
